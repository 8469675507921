import { clearUserData, getAuthInfo, getUserInfo, isLoggedIn } from '@root-config/auth'

import { AppConfig, AppLocation } from '@root-config/types'
import { escapeRegExp } from 'lodash-es'
import { truthy } from '@lasso/shared/utils'
import { isAppActive } from '@root-config/utils'

const isDev = process.env.NODE_ENV === 'development'

export const config: Array<AppConfig | null> = [
  {
    id: '@lasso/root-app',
    activeWhen: () => true,
    loader: false,
    isAside: true,
  },

  {
    id: '@lasso/pages',
    activeWhen: location => ['app/404', 'app/403'].includes(location.pathname)
      || location.pathname.startsWith('app/pages'),
  },

  {
    id: '@lasso/sidenav',
    activeWhen: location => !location.pathname.startsWith('app/auth'),
    loader: 'sidenav',
    shouldHideLoader: false,
    isAside: true,
  },

  {
    id: '@lasso/angularjs',
    activeWhen: location => !location.pathname.startsWith('app/'),
    shouldHideLoader: false,
  },

  { id: '@lasso/auth', activeWhen: 'app/auth', shouldHideLoader: false },
  { id: '@lasso/monetization', activeWhen: 'app/monetization' },
  {
    id: '@lasso/activation',
    activeWhen: location => location.pathname === 'app/activation'
      || location.pathname.startsWith('app/activation/campaign'),
  },
  {
    id: '@lasso/activation-objects',
    activeWhen: location => location.pathname.startsWith('app/activation')
      && location.pathname !== 'app/activation'
      && !location.pathname.startsWith('app/activation/campaign'),
  },
  { id: '@lasso/audience', activeWhen: 'app/audience' },
  { id: '@lasso/measurement', activeWhen: 'app/measurement' },
  { id: '@lasso/workflows', activeWhen: 'app/workflows' },
  { id: '@lasso/reporting', activeWhen: 'app/reporting' },
  { id: '@lasso/admin', activeWhen: 'app/admin' },

  isDev ? { id: '@lasso/template-app', activeWhen: 'app/template-app' } : null,
]

const legacyV2PathRegex = new RegExp(`^${escapeRegExp('v2/')}`)

const redirects = new Map([
  ['pages/change-password', 'app/auth/change-password'],
  ['settings/account-settings', 'app/auth/account-settings'],
])

export const getRedirectPath = (loc: AppLocation): string | null => {
  const _isLoggedIn = isLoggedIn()
  const userInfo = getUserInfo()
  const authInfo = getAuthInfo()

  // Fake route to redirect to the defaultPath while ensuring that the current app is re-mounted
  // TODO: remove when angularjs app is removed, since that's the only app that needs this
  const isRedirectRoute = loc.pathname === 'app/redirect'
  const isAuthPage = loc.pathname.startsWith('app/auth') && ![
    'app/auth/change-password',
    'app/auth/account-settings',
  ].includes(loc.pathname)
  const isIgnoredByAuthReturnLink = ['pages/403', 'app/redirect', 'app/auth/change-password'].includes(loc.pathname)

  // Redirects from angular to vue
  if (redirects.has(loc.pathname)) {
    return redirects.get(loc.pathname)!
  }

  // Legacy vueapp redirect
  if (loc.pathname.match(legacyV2PathRegex)) {
    const newPath = loc.pathname.replace(legacyV2PathRegex, 'app/')
    return newPath + loc.search
  }

  // Login page redirect when user is not logged in
  if (!_isLoggedIn && !isAuthPage) {
    if (!isIgnoredByAuthReturnLink) {
      localStorage.setItem('authReturnLink', loc.pathname + loc.search)
    }
    clearUserData()
    return 'app/auth'
  }

  // Default path redirect from login when user is logged in
  if (_isLoggedIn && isAuthPage) {
    return authInfo?.defaultPath || '/'
  }

  // First time user password change
  if (_isLoggedIn && userInfo?.firstTimeUser && loc.pathname !== 'app/auth/change-password') {
    return 'app/auth/change-password'
  }

  const isNoActiveApps = config.filter(truthy).every(app => app.isAside || !isAppActive(app.activeWhen, loc))
  // Redirect when no app is active
  if (isNoActiveApps && !isRedirectRoute) {
    return 'pages/404'
  }

  // Default path redirect on root path
  if (loc.pathname === '/' || isRedirectRoute) {
    const defaultPath = authInfo?.defaultPath

    if (defaultPath && defaultPath !== loc.pathname) {
      return defaultPath
    }
  }

  return null
}
